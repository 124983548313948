<div
  class="relative bg-cover bg-center bg-no-repeat h-screen background-image-container"
>
  <div><img src="/assets/images/login-img.svg" class="bottom-image" /></div>
  <div
    class="login-main-container container mx-auto px-4 h-full flex items-center justify-center md:justify-between"
  >
    <div
      class="w-full md:w-1/2 xl:w-1/3 bg-white text-black p-10 rounded-3xl shadow-lg z-10 login-container"
    >
      <div class="login-content">
        <img
          src="/assets/images/image 5.svg"
          alt="Daikin Logo"
          class="mx-auto"
        />
        <h2 class="text-3xl font-bold mt-[10px] mb-10 heading">
          {{ "LOGIN_HEADING" | translate }}
        </h2>
      </div>
      <app-button [name]="'LOGIN'" (onClick)="onLogin()"></app-button>
    </div>
  </div>
</div>
